const tableFields = [
    // {
    //     name: 'report_type',
    //     title: 'Report Type',
    //     sortField: 'report_type',
    //     titleClass: '',
    //     dataClass: ''
    // },
    {
        name: 'content',
        title: 'Contents',
        titleClass: '',
        dataClass: ''
    },
    {
        name: 'disease_name',
        title: 'Disease',
        titleClass: '',
        dataClass: ''
    },
    {
        name: 'reported_by',
        title: 'Reported By',
        titleClass: '',
        dataClass: ''
    },
    {
        name: '__slot:actions',
        title: 'Actions',
        titleClass: '',
        dataClass: ''
    }
];

export default tableFields;
