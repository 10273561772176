<template>
    <div>
        <container>
            <inner-page-card heading="User Registration">
                <div class="h-80vh">
                    <transition-fade>
                        <div class="fl-x-cc h-100p" v-if="!showForm" key="anim">
                            <loading-animation></loading-animation>
                        </div>
                        <!--Add Staff Form-->
                        <b-form :save-url="addUrl" @failure="addFormFailure" @success="StaffAddFormSuccess"
                                v-slot="{model, loading}" class="pb-3">
                            <row>
                                <col-12>
                                    <validated-input-colored ref="usernameField" v-model="model.username" :rules="rules.username"
                                                             label="Username <span class='text-danger'>*</span> :"/>
                                    <form-info>Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only.</form-info>
                                </col-12>
                                <col-12 mt="3">
                                    <validated-input-colored v-model="model.first_name" :rules="rules.first_name" label="First name :"/>
                                </col-12>
                                <col-12>
                                    <validated-input-colored v-model="model.last_name" :rules="rules.last_name" label="Last name :"/>
                                </col-12>
                                <col-12>
                                    <validated-input-colored type="email" v-model="model.email" :rules="rules.email" label="Email :"/>
                                </col-12>
                                <col-12>
                                    <validated-checkbox v-model="model.is_staff" label-classes="text-third font-weight-700" label="Staff Status"/>
                                </col-12>
                                <col-12>
                                    <validated-input-colored ref="passwordField" v-model="model.password" type="password" label="Password <span class='text-danger'>*</span> :"/>
                                    <form-info ml="4">
                                        <ul>
                                            <li>Your password can't be too similar to your other personal information.</li>
                                            <li>Your password must contain at least 8 characters.</li>
                                            <li>Your password can't be a commonly used password.</li>
                                            <li>Your password can't be entirely numeric.</li>
                                        </ul>
                                    </form-info>
                                </col-12>
                                <col-12 mt="3">
                                    <validated-input-colored v-model="model.confirm_password" type="password" label="Password Confirmation <span class='text-danger'>*</span> :"/>
                                </col-12>
                                <col-12>
                                    <save-btn loading-text="Saving..." type="submit" :disabled="loading" :loading="loading"/>
                                </col-12>
                            </row>
                        </b-form>
                    </transition-fade>
                </div>
            </inner-page-card>
        </container>
    </div>
</template>

<script>
import InnerPageCard from '../../../components/InnerPageCard';
import FormInfo from '../../../components/form/FormInfo';
import urls from '../../../data/urls';

export default {
    name: 'AddStaffPage',
    components: { FormInfo, InnerPageCard },
    data () {
        return {
            addUrl: urls.userManagement.staff.addEdit,
            model: {
                username: '',
                first_name: '',
                last_name: '',
                email: '',
                is_staff: false,
                password: '',
                confirm_password: ''
            },
            rules: {
                username: {
                    required: true
                },
                first_name: {
                    required: false
                },
                last_name: {
                    required: false
                },
                email: {
                    required: true
                },
                designation: {
                    required: false
                },
                college: {
                    required: false
                },
                batch: {
                    required: false
                },
                reg_no: {
                    required: false
                },
                phone_number: {
                    required: false
                },
                country: {
                    required: false
                },
                place: {
                    required: false
                },
                password: {
                    required: true
                },
                confirm_password: {
                    required: true
                }
            },
            showForm: true
        };
    },
    methods: {
        StaffAddFormSuccess (response) {
            // Add Staff Form Success
            if (response.data.success) {
                this.$notify(response.data.message, 'Success', {
                    type: 'success'
                });
            }
            this.showForm = false;
            const that = this;
            setTimeout(function () {
                that.showForm = true;
            }, 1000);
        },
        addFormFailure (response) {
            const errors = response.data.errors;
            for (const key in errors) {
                if (Object.prototype.hasOwnProperty.call(errors, key)) {
                    const errorMessage = `${key}:  ${errors[key]}`;
                    this.$notify(errorMessage, 'Error', { type: 'warning' });
                }
            }
        }
    },
    mounted () {
        this.$refs.usernameField.$el.children[1].children[0].autocomplete = 'off';
        this.$refs.passwordField.$el.children[1].children[0].autocomplete = 'new-password';
        this.$refs.usernameField.$el.children[1].children[0].value = '';
    }
};
</script>

<style scoped>

</style>
