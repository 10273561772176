<template>
    <table-page heading="Transactions">

        <vue-table-card :url="listUrl" :fields="fields" ref="table">
            <template #actions="{rowData}">
                <btn-group>
                    <view-btn text="View" @click="viewInfo(rowData)"/>
                </btn-group>
            </template>
            <template #footer>
                <modal width="35r" title="Transaction Info" ref="transactionViewModal">
                    <p class="pl-2"><span class="font-proxima-bold">User </span>: ADMIN</p>
                    <details-card :heading="'Plan name : ' + currentTransactionItem.name" v-if="currentTransactionItem && currentTransactionItem.plan">
                        <details-list>
                            <details-list-item label="Transaction ID" :value="currentTransactionItem.transaction_id"/>
                            <details-list-item label="Status" :value="currentTransactionItem.status"/>
                            <details-list-item label="Validity" :value="currentTransactionItem.plan.expiry_months"/>
                            <details-list-item label="Initiated Date" :value="currentTransactionItem.created_date"/>
                            <details-list-item label="Transaction Date" :value="currentTransactionItem.transaction_date"/>
                            <details-list-item label="Amount" :value="currentTransactionItem.amount"/>
                            <details-list-item label="Payment Method" :value="currentTransactionItem.payment_method_type"/>
                            <details-list-item label="Bank ID" :value="currentTransactionItem.bank_id"/>
                            <details-list-item label="Bank Referal No" :value="currentTransactionItem.bank_ref_no"/>
                            <details-list-item label="Refund Status" :value="currentTransactionItem.refund_status"/>

                        </details-list>
                        <btn-primary class="mt-3"
                                     v-if="currentTransactionItem && currentTransactionItem.status === 'Success' && currentTransactionItem.refund_status === false"
                                     @click="makeRefund(currentTransactionItem.id)"
                        >Refund
                        </btn-primary>
                        <div v-if="makeRefundClicked && refundErrorMessage !==''" class="text-danger mt-2">{{refundErrorMessage}}</div>
                        <div v-if="makeRefundClicked && refundSuccessMessage !==''" class="text-success mt-2">{{refundSuccessMessage}}</div>
                    </details-card>
                </modal>
            </template>
        </vue-table-card>

    </table-page>
</template>

<script>
import InnerPageCard from '@components/InnerPageCard';
import TransactionPage from '@/views/transactions/transaction/transaction-fields';
import urls from '../../../data/urls';
import axios from 'secure-axios';

export default {
    name: 'TransactionsPage',
    components: InnerPageCard,
    data () {
        return {
            fields: TransactionPage,
            listUrl: urls.transactions.list,
            detailsUrl: urls.transactions.detail,
            refundUrl: urls.transactions.refund,
            currentTransactionItem: [],
            data: [
                {
                    name: 'alfredo',
                    phone_number: '811187885',
                    transaction_id: '811187885',
                    status: '811187885',
                    amount: '811187885',
                    expiry_month: '811187885',
                    initiated_date: '811187885'

                }
            ],
            refundSuccessMessage: '',
            refundErrorMessage: '',
            makeRefundClicked: false
        };
    },
    methods: {
        async viewInfo (item) {
            this.$router.push('/transaction-details/' + item.id);
            // console.log(item);
            // const that = this;
            // const response = await axios.form(
            //     this.detailsUrl, { id: item.id }
            // ).catch(function (exception) {
            //     that.serverError();
            // });
            // const json = response.data;
            // const dataItem = json.data;
            // console.log('json', json.data);
            // this.currentTransactionItem = dataItem;
            // this.$refs.transactionViewModal.show();
            // this.makeRefundClicked = false;
        },
        async makeRefund (transactionId) {
            this.makeRefundClicked = true;
            try {
                const response = await axios.form(this.refundUrl, { id: transactionId });
                const result = await response.data;
                console.log('result', result, 'response', response);
                this.data = result;
                if (result.success === true) {
                    this.$refs.transactionViewModal.close();
                    this.$notify('Refunded Successfully', 'Success', { type: 'success' });
                    console.log('refund success');
                    this.refundSuccessMessage = 'Refunded Successfully';
                } else {
                    const errors = response.data.errors;
                    console.log('errors', errors);
                    this.refundErrorMessage = result.message;
                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            let errorMessage;
                            if (key === '__all__') {
                                errorMessage = `${errors[key]}`;
                            } else {
                                errorMessage = `${key}:  ${errors[key]}`;
                            }
                            this.refundErrorMessage = errorMessage;
                            this.$notify(errorMessage, 'Warning', { type: 'warning' });
                        }
                    }
                }
            } catch (error) {
                console.log(error);
                // that.$notify(
                //     'Something Went Wrong..!!',
                //     error.response.status + ' ' + error.response.statusText,
                //     { type: 'danger' }
                // );
                // that.loading = false;
            }
            // this.makeRefundClicked = false;
        }
        // },
        // viewUser (item) {
        //     console.log(item);
        //     this.$refs.transactionViewModal.show();
        // }
    }

};
</script>

<style scoped>

</style>
