<template>
    <div>
        <container>
            <inner-page-card heading="Reports">
                <h5 class="text-center text-danger">PENDING</h5>
                <div class="fl-x fl-j-c mt-3" v-if="loading">
                    <loading-animation></loading-animation>
                </div>
                <template v-else>
                    <issue-report-item class="mt-3 w-100p" :section="item.brand" :message="item.message" :reported="item.reported" v-for="(item,i) in detailsData" :key="i">
                        <template #buttons>
                            <btn color="success" text="Fixed" @click="makeFixed(item)"/>
                        </template>
                    </issue-report-item>
                </template>
            </inner-page-card>
        </container>
    </div>
</template>

<script>
import IssueReportItem from './components/IssueReportItem';
import urls from '../../../data/urls';
import axios from 'secure-axios';

export default {
    name: 'IssuesReportedPage',
    components: { IssueReportItem },
    data () {
        return {
            listUrl: urls.drugs.report.list,
            drugReportFixedUrl: urls.drugs.report.drugReportFixed,
            fixedReportList: urls.drugs.report.fixedReportList,
            detailsData: null,
            loading: false
        };
    },
    mounted () {
        this.loadDetails();
    },
    methods: {
        async loadDetails () {
            console.log('load details');
            this.loading = true;
            try {
                const response = await axios.get(this.listUrl);
                this.detailsData = response.data.data;
            } catch (error) {
                console.log('serverError');
                // this.serverError();
            }
            this.loading = false;
        },
        async makeFixed (item) {
            // ban user post method
            const that = this;
            const response = await axios.form(this.drugReportFixedUrl, { id: item.id })
                .catch(function (exception) {
                    that.serverError();
                });
            const responseData = await response.data;
            if (responseData.success) {
                this.$notify('Issue Marked as Fixed Successfully', 'Success', {
                    type: 'success'
                });
            }
            if (responseData.error) {
                that.$notify('Error fount..!', 'Error',
                    {
                        type: 'warning',
                        duration: 3000,
                        permanent: false
                    }
                );
            }
            this.loadDetails();
        }
    }
};
</script>

<style scoped>

</style>
