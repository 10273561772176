<template>
    <container>
        <inner-page-card heading="Flash">
            <template #buttons>
                <add-btn @click="$refs.addModal.show()"/>
            </template>
            <vue-table-card :url="listUrl" :fields="fields" :table-data="data" ref="table">
                <template #actions="{rowData}">
                    <btn-group>
                        <activate-btn @click="ActivateFlash(rowData, 'activate')" v-if="!rowData.is_enabled"/>
                        <activate-btn text="Inactivate" @click="ActivateFlash(rowData, 'inactivate')" v-else/>
                        <edit-btn @click="setEdit (rowData)"/>
                        <delete-btn @click="setDeleteUser(rowData)"/>
                    </btn-group>
                </template>
                <template #footer>
                    <edit-modal ref="addModal" title="Flash" width="40r">
                        <b-form :save-url="addUrl" @failure="userEditFailure" @success="formSuccess('M')" v-slot="{model, loading}">
                            <row>
                                <col-12 class="fl-x">
                                    <validated-input label="Heading <span class='text-danger'>*</span>:" class="col-6 pl-0" v-model="model.heading" :rules="rules.heading"
                                                     :disabled="loading"/>
                                    <validated-text-area label="Description <span class='text-danger'>*</span>:" class="col-6 pr-0" v-model="model.description"
                                                         :rules="rules.description"
                                                         :disabled="loading"/>
                                </col-12>
                                <col-12>
                                    <submit-btn loading-text="Saving..." size="sm" :disabled="loading"
                                                :loading="loading"/>
                                </col-12>
                            </row>
                        </b-form>
                    </edit-modal>
                    <activate-modal :title="statusChangeAction" :ok-button="statusChangeAction" ref="ActivateModal" @response="FlashActivateSuccess" :url="enableUrl" :params="activateItem">
                        <p>You are about to <span class="text-lowercase">{{statusChangeAction}}</span> <b>{{activateItem.heading}}</b>.
                            Are you sure?
                        </p>
                        <template #loading>
                            <loading-animation/>
                            Please wait while we <span class="text-lowercase">{{statusChangeAction}}</span> the flash...
                        </template>
                    </activate-modal>
                    <edit-modal ref="EditModal">
                        <b-form :save-url="addUrl" @failure="userEditFailure" @success="formSuccess('M')" v-slot="{model, loading}"
                                :initial-data="editingItem">
                            <row>
                                <col-12 class="fl-x">
                                    <validated-input label="Heading <span class='text-danger'>*</span>:" class="col-6 pl-0" v-model="model.heading" :rules="rules.heading"
                                                     :disabled="loading"/>
                                    <validated-text-area label="Description <span class='text-danger'>*</span>:" class="col-6 pr-0" v-model="model.description"
                                                         :rules="rules.description"
                                                         :disabled="loading"/>
                                </col-12>
                                <col-12>
                                    <submit-btn loading-text="Saving..." size="sm" :disabled="loading"
                                                :loading="loading"/>
                                </col-12>
                            </row>
                        </b-form>
                    </edit-modal>
                    <delete-modal ref="deleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteComplete">
                        <p>You are about to delete
                            <b v-if="deletingItem"> {{ deletingItem.heading }}</b>
                            Are you sure about it ?</p>
                        <template #loading>
                            <loading-animation/>
                            Please wait while we delete <b v-html="deletingItem && deletingItem.heading"></b>.
                        </template>
                    </delete-modal>
                </template>
            </vue-table-card>
        </inner-page-card>
    </container>
</template>

<script>
import tableFields from './flash';
import urls from '../../data/urls';
// import axios from 'secure-axios';

export default {
    name: 'FlashPage',
    data () {
        return {
            fields: tableFields,
            listUrl: urls.drugs.flash.list,
            addUrl: urls.drugs.flash.addEdit,
            deleteUrl: urls.drugs.flash.delete,
            enableUrl: urls.drugs.flash.enableUrl,
            deletingItem: {
                name: ''
            },
            activateItem: {
                id: ''
            },
            editingItem: null,
            rules: {
                heading: {
                    required: true
                },
                description: {
                    required: true
                }
            },
            data: [
                {
                    heading: 'Common names in Parasitology',
                    description: 'Sreerag, Gopu, Sanoop, Sarath TP'
                }
            ],
            statusChangeAction: ''
        };
    },
    methods: {
        formSuccess (action) {
            const refs = this.$refs;
            this.$refs.addModal.close();
            this.$refs.EditModal.close();
            if (action === 'A') {
                this.$notify('Flash Added Successfully', 'Success', {
                    type: 'success'
                });
            } else {
                this.$notify('Flash Updated Successfully', 'Success', {
                    type: 'success'
                });
            }
            refs.table.refreshTable();

            this.editingItem = null;
        },
        addModal (item) {
            this.editingItem = { ...item };
            this.$refs.addModal.show();
        },
        setEdit (item) {
            this.editingItem = { ...item };
            console.log('editing item', this.editingItem);
            this.$refs.EditModal.show();
        },
        setDeleteUser (item) {
            console.log(item);
            this.deletingItem = { ...item };
            this.$refs.deleteModal.show();
        },
        deleteComplete (response) {
            this.deletingItem = null;
            this.$refs.deleteModal.close();
            this.$notify('Flash Deleted Successfully', 'Success', {
                type: 'success'
            });
            this.$refs.table.refreshTable();
        },
        userEditFailure (response) {
            const errors = response.data.errors;
            for (const key in errors) {
                if (Object.prototype.hasOwnProperty.call(errors, key)) {
                    const errorMessage = `${key}:  ${errors[key]}`;
                    this.$notify(errorMessage, 'Warning', { type: 'warning' });
                }
            }
        },
        ActivateFlash (item, action) {
            if (action === 'activate') {
                this.statusChangeAction = 'Activate';
            } else {
                this.statusChangeAction = 'Inactivate';
            }
            this.activateItem = { ...item };
            this.$refs.ActivateModal.show();
        },
        FlashActivateSuccess (response) {
            if (response.error === true) {
                this.$notify({
                    message: response.message ? response.message : 'Failed to activate Flash',
                    title: 'Failed',
                    type: 'danger'
                });
            } else {
                this.$notify({
                    message: response.message ? response.message : 'Flash Activated Successfully',
                    title: 'Success',
                    type: 'success'
                });
            }
            this.activateItem = null;
            this.$refs.ActivateModal.close();
            this.$refs.table.refreshTable();
        }
    }
};
</script>

<style scoped>

</style>
